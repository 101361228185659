window.Vue = require('vue').default;
import Vuex from 'vuex'
import Vue from 'vue'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

import tooltips from './modules/tooltips'

import backend from './modules/backend'
import dialogs from './modules/dialogs'
import mapSharing from './modules/map-sharing'
import contact from './modules/contact'
import siteConfig from './modules/site-config'

import SiteTitleWatcherPlugin from './plugins/SiteTitleWatcher'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mode: 'move',
    toolType: 'area',
    imperial: true,
    primaryUnits: {},
    activeStatusTab: 0,
    address: '',    
    displayAddress: '',
    shapes: [],
    lastMapClickEvent: null,
    nextLatLngPan: false,
    searchModalVisible: false,
    saveModalVisible: false,        
    tutorialModalVisible: false,
    affiliateModalVisible: false,    
    unitModalVisible: false,    
    globalZIndex: 0,
    mapCenter: {},
    meta: {},
    mapDrag: false,
    toolbarsHidden: false,
    mapZoom: {},
    mapEditingEnabled: true,
    currentSlotName: '',   
    projectName: '', 
    saveSlots: {},
    introCompleted: false,
    publicComputerWarningShown: false,
    introTipsShown: false,
    affiliateModalShown: false,
    currentControlOverlay: null,
    loading: false,
    appClicked: false,
    uuid: null
  },
  modules: { 
    backend,
    tooltips,
    dialogs,
    mapSharing,
    contact,
    siteConfig    
  },
  plugins: [
    SiteTitleWatcherPlugin
  ],
  mutations,
  actions,
  getters
});