/* mutations.js */
import { get, pick, omit, defaults } from 'lodash'
import { v4 as uuidv4 } from 'uuid';
import { shapeDefaults } from '../constants';

import config from '@/app-config'
import Vue from 'vue';

const WARNINGS = ['publicComputerWarningShown'];

export default {
  INITIALIZE_STORE (state, newState = null) {
    console.log('initializing store');  
    if (localStorage.getItem('store') || newState) {
      console.log('loading store');
      // extract
      let originalWarnings = pick(state, WARNINGS);
      // if a newState is passed, use that, otherwise use local storage, strip warnings and meta data      
      let storageState = JSON.parse(localStorage.getItem('store') || '{}');

      let loadedState = omit({
          ...storageState,
          ...(newState) ? newState : {}
      },
        [
          ...WARNINGS
        ]
      );

      // modify areas to ensure none are loaded with a "selected" state
      if (loadedState.hasOwnProperty('areas')) {
        // migrate users with old 'areas' property to shapes
        loadedState.shapes = _.cloneDeep(loadedState.areas);
        delete loadedState.areas;
      }

      // migrate users with old 'primaryUnit' property
      if (loadedState.hasOwnProperty('primaryUnit')) {
        delete loadedState.primaryUnit;
      }

      let loadedShapes = loadedState.shapes.map((s) => defaults({...s, selected: false }, shapeDefaults));
      
      // reset modal states
      Object.assign(loadedState, {
        ...(newState) ? originalWarnings : {}, // if a state is being loaded, include the original warning statuses, otherwise don't
        source: (newState && newState.source) ? newState.source : 'local',
        saveModalVisible: false,
        searchModalVisible: false,
        tutorialModalVisible: false,         
        affiliateModalVisible: false,
        affiliateModalShown: false,
        unitModalVisible: false,
        activeStatusTab: 0,
        currentControlOverlay: null,
        primaryUnits: {},
        mode: 'move',        
        shapes: loadedShapes,    
        mapCenter: {
          fromSave: (loadedState.mapCenter) ? loadedState.mapCenter.fromSave : false,
          fromMap: false,
          lat: (loadedState.mapCenter) ? loadedState.mapCenter.lat : null,
          lng: (loadedState.mapCenter) ? loadedState.mapCenter.lng : null
        },
        mapDrag: false,
        toolbarsHidden: false,
        mapZoom: {
          fromMap: false,
          number: loadedState.mapZoom.number
        },
        meta: loadedState.meta || {},
        loading: false,
        // used to notify tooltips the focus was lost
        appClicked: false,
        uuid: loadedState.uuid ? loadedState.uuid : uuidv4()
      });

      // set all warnings that don't exist in the loaded state to false, avoiding undefined errors
      loadedState = defaults(loadedState, WARNINGS.reduce((prev, v) => { prev[v] = false; return prev; }, {}));
      Object.assign(state, loadedState);
    }
  },
  RESET_STATE (state) {
    console.log('resetting store');
    localStorage.removeItem('store');
    Object.assign(state, {
      mode: 'move',
      toolType: 'area',
      imperial: true,
      primaryUnits: {},
      activeStatusTab: 0,
      address: '',
      displayAddress: '',
      shapes: [],
      meta: {},
      projectName: '',
      lastMapClickEvent: null,
      nextLatLngPan: false,
      unitModalVisible: false,
      globalZIndex: 0,
      mapCenter: {},
      mapZoom: {},
      currentControlOverlay: null,
      loading: false,
      appClicked: false,
      uuid: uuidv4()
    });
  },

  CLEAR_LEGACY_SAVE_SLOTS (state) {
    Vue.set(state, 'saveSlots', {});
  },
  SET_META (state, meta) {
    state.meta = meta;
  },
  SET_MODE (state, mode) {
    state.mode = mode;
  },
  SET_CURRENT_CONTROL_OVERLAY (state, overlay) {
    state.currentControlOverlay = overlay;
  },
  SET_TOOL_TYPE (state, type) {
    state.toolType = type;
  },
  SET_IMPERIAL (state, status) {
    state.imperial = status;
  },
  SET_PRIMARY_UNIT (state, unit) {
    Vue.set(state.primaryUnits, unit.type, unit);    
  },
  SET_ADDRESS (state, address) {
    state.address = address;
  },
  SET_DISPLAY_ADDRESS (state, address) {
    state.displayAddress = address;
  },
  SET_PROJECT_NAME (state, name) {
    state.projectName = name;
  },
  SET_SHAPES (state, shapes) {
    state.shapes = shapes;
  },
  RESET_DISPLAY_INDEXES (state) {
    state.shapes.forEach((s, idx) => {
      s.displayIndex = idx + 1;
      state.shapes.splice(idx, 1, s);
    });
  },
  REFRESH_SHAPE (state, shape) {      
    let idx = state.shapes.findIndex(s => s.indexID == shape.indexID);
    state.shapes.splice(idx, 1, shape);
  },
  SET_CURRENT_SHAPE (state, newId) {
    state.shapes.forEach((s, k) => {
      s.selected = ((s.indexID != newId) || !newId) ? false : true;
      state.shapes.splice(k, 1, s);
    });
  },
  SET_CURRENT_SLOT (state, slotName) {
    state.currentSlotName = slotName;
  },
  SET_SHAPE_CUTOUT (state, { indexID, cutout }) {
    let idx = state.shapes.findIndex(s => s.indexID == indexID);
    state.shapes.splice(idx, 1, Object.assign(state.shapes[idx], { cutout }));
  },
  SET_SHAPE_DISTANCE_VISIBILE (state, { indexID, visible}) {
    let idx = state.shapes.findIndex(s => s.indexID == indexID);
    state.shapes.splice(idx, 1, Object.assign(state.shapes[idx], { 'distanceVisible': visible }));
  },
  SET_SHAPE_LABEL_TEXT (state, { indexID, labelText }) {
    let idx = state.shapes.findIndex(s => s.indexID == indexID);
    state.shapes.splice(idx, 1, Object.assign(state.shapes[idx], { 'labelText': labelText }));
  },
  REMOVE_SHAPE (state, shape) {
    let indexID = get(shape, 'indexID', shape);
    let idx = state.shapes.findIndex(s => s.indexID == indexID);
    if (idx > -1) state.shapes.splice(idx, 1);
  },
  ADD_SHAPE (state, cfg) {
    state.shapes.push(cfg);
  },
  REPLACE_SHAPE (state, repl) {
    state.shapes.splice(repl.idx, 1, repl.shape);
  },
  SET_SHAPE_HIGHLIGHT (state, { idx, highlight }) {
    state.shapes.splice(idx, 1, Object.assign(state.shapes[idx], { highlighted: highlight }));
  },
  SET_SEARCH_MODAL_VISIBLE (state, visible) {
    state.searchModalVisible = (visible === true) ? true : false;
  },
  SET_SAVE_MODAL_VISIBLE (state, visible) {
    state.saveModalVisible = (visible === true) ? true : false;
  },
  SET_TUTORIAL_MODAL_VISIBLE (state, visible) {
    state.tutorialModalVisible = (visible === true) ? true : false;
  },
  SET_AFFILIATE_MODAL_VISIBLE (state, visible) {
    state.affiliateModalVisible = (visible === true) ? true : false;
  },
  SET_ACTIVE_STATUS_TAB (state, tab) {
    state.activeStatusTab = tab;
  },
  SET_UNIT_MODAL_VISIBLE (state, visible) {
    state.unitModalVisible = (visible === true) ? true : false;
  },
  SET_GLOBAL_ZINDEX (state, zIndex) {
    state.globalZIndex = zIndex;
  },
  SET_MAP_CENTER (state, latLng) {
    if (latLng && latLng.lat && latLng.lng) {
      state.mapCenter = latLng;
    } else {
      state.mapCenter = null;
    }
  },
  SET_MAP_DRAG (state, dragging) {
    state.mapDrag = dragging;
  },
  SET_TOOLBARS_HIDDEN (state, hide) {
    state.toolbarsHidden = hide;
  },
  SET_MAP_ZOOM (state, zoom) {
    if (zoom.number) {
      state.mapZoom = zoom;
    }
  },
  SET_MAP_EDITING_ENABLED (state, enabled) {
    state.shapes.forEach((s, k) => {
      s.editingEnabled = enabled;
      state.shapes.splice(k, 1, s);
    });
  },
  SET_APP_CLICKED(state, { clicked=true }={}) {
    state.appClicked = clicked;
  },
  SET_INTRO_COMPLETED (state) {
    state.introCompleted = true;
  },
  SET_PUBLIC_COMPUTER_WARNING_SHOWN (state) {
    state.publicComputerWarningShown = true;
  },
  SET_INTRO_TIPS_SHOWN (state) {
    state.introTipsShown = true;
  },
  SET_AFFILIATE_MODAL_SHOWN (state) {
    state.affiliateModalShown = true;
  },
  SET_LAST_MAP_CLICK_EVENT (state, event) {
    state.lastMapClickEvent = event;
  },
  SET_LOADING(state, { loading }) {
    state.loading = (loading) ? true : false;
  },
}