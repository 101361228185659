<style>
div#map {
  position: relative;
}

div.marker-control-ct {
  display: none;
}

div.marker-control-buttons {
  position: absolute;
}

div.marker-control-buttons ion-icon {
  font-size: 2.5em;
  background-color: white;
  border-radius: 50%;
}

div.marker-control-buttons ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

div.marker-control-buttons li {
  position: absolute;
  -webkit-transition: all 2s linear;
  -moz-transition: all 2s linear;
  transition: all 2s linear;
  margin: 0;
  padding: 0;
}
</style>

<template>
  <div ref="container" id="map"></div>
</template>

<script>
import Dispatcher from '../events.js';
import Vuex from 'vuex';

import { differenceWith, get, cloneDeep, defaults } from 'lodash';
import { NEW_AREA, snackbarDefaults } from '../constants.js';

import ControlOverlay from './ControlOverlay.js'
import ClickableBehavior from './behaviors/ClickableBehavior';

import MapShapes from './Shapes';

let globalZIndex = 1;
let dispatcher = new Dispatcher();

const geocodeUrl = 'https://maps.googleapis.com/maps/api/geocode/json?';

const addressMarkerLabel = '';

const cursorDraw = 'crosshair';
const cursorNormal = 'pointer';
const cursorMove = 'move';
const cursorNotAllowed = 'not-allowed';

export default {
  data() {
    return {
      map: null,
      addressEl: null,
      addressMarker: null,
      latLng: { lat: 0, lng: 0},
      addressEl: null,
      zIndex: 0,
      cursor: null,
      click: 0,
      initedShapes: [],
      currentInitedShape: null,
      shapeSelectOverlay: null,      
      lastUpdate: 0,
      idleListener: null
    }
  },
  props: {
    enableEditing: {
      type: Boolean,
      default: true
    }
  },
  watch: {        
    '$route': {
      handler (newRoute, oldRoute) {
        if (oldRoute && oldRoute.name == 'Search') {
          this.$store.dispatch('searchAddress', oldRoute.params.address);
        }
        
        this.setAddressMarkerVisible(newRoute.name === 'ConfirmAddress');
        this.setAddressMarkerDraggable(newRoute.name === 'ConfirmAddress');
      },
      immediate: true
    },
    enableEditing: {
      handler (newVal, oldVal) {
        this.initedShapes.forEach(shape => shape.setEditingEnabled(newVal));
      },
      immediate: true
    },
    address: {
      handler (newAddress, oldAddress) {        
        if (newAddress) {
          if (!this.mapCenter || !this.mapCenter.lat || !this.mapCenter.lng) {
            this.updateAddress(newAddress);
          }
        } else {
          // this.setAddressMarkerVisible(false);
        }
      }
    },
    mode: {
      handler(mode) {
        this.setCursor();
        this.initedShapes.forEach((shape) => shape.setGlobalMode(mode));
        
        if (mode == 'free') {
          this.$buefy.snackbar.open({
            ...snackbarDefaults,
            queue: false,
            message: `Click or tap anywhere to start drawing.`
          });

          this.initedShapes.forEach(shape => shape.setMode('free'));          
          this.$store.dispatch('setCurrentShape', null);          
          return;
        }

        if (this.currentInitedShape) {
          console.log('map mode watcher: ' + mode);
          this.currentInitedShape.setMode(mode);
          return;
        }        
      },
      immediate: true
    },
    async mapCenter(newLatLng, oldLatLng) {      
      if (newLatLng && newLatLng.lat && newLatLng.lng && !newLatLng.fromMap) {   
        if (newLatLng.fromSave) {          
          await this.waitForMap();
                 
          Vue.nextTick(() => {
            this.map.setCenter(newLatLng);

            setTimeout(() => {
              this.map.fitBounds(this.getBounds(), 0);
            }, 1000);            
          });
        } else {
          console.log('mapcenter');
          this.map.setCenter(newLatLng);
        }
      }
    },
    mapZoom: {
      handler(newZoom, oldZoom) {        
        if (!newZoom.fromMap && newZoom.number) {
          // this.map.setZoom(newZoom.number)
        }

        this.initedShapes.forEach(shape => {
          shape.setMapZoom(newZoom.number);
        });
      },
      immediate: true
    },
    shapes: {
      handler(shapes) {
        // initialize any brand new areas
        shapes
          .filter((s) => s instanceof NEW_AREA)
          .forEach((v, k, s) => {            
            let shapeCfg = this.initShape({ options: v }).getState();
            
            this.$store.dispatch('initNewShape', shapeCfg).then(() => {
              this.$store.dispatch('setCurrentShape', shapeCfg);
            });
          });

        // identify new areas and add them to the inited array
        differenceWith(shapes, this.initedShapes, (shape, initedShape) => {          
          return (shape.indexID == initedShape.indexID);
        }).forEach((newShape) => {
          this.initShape({ loadState: newShape, options: { editingEnabled: this.enableEditing } });
        });

        // update already inited areas with new configuration
        shapes.forEach((newCfg) => {
          let existingShape = this.getShapeById(newCfg.indexID);
          if (existingShape) existingShape.updateFromState(newCfg);
        });

        // identify deleted areas and remove them from the inited array
        differenceWith(this.initedShapes, shapes, (initedShape, shape) => {
          return (shape.indexID == initedShape.indexID);
        }).forEach((removedShape) => {
          // console.log('removed: ' + removedArea.indexID);
          removedShape.delete();
          this.initedShapes.splice(this.initedShapes.findIndex((shape) => shape.indexID == removedShape.indexID), 1);
        });
      },
      immediate: true,
      deep: true
    },
    currentInitedShape(initedShape, oldInitedShape) {
      this.initedShapes.forEach((shape) => shape.setGlobalSelectedShape(initedShape));
      if (initedShape) {
        initedShape.setMode(this.mode);
      } else {
        this.setCursor();        
      }

      if (!oldInitedShape || initedShape.indexID !== oldInitedShape.indexID) {
        this.$store.dispatch('setToolbarsHidden', false);
      }
    },
    currentShape(shape, oldShape) {
      this.currentInitedShape = this.getShapeById((shape) ? shape.indexID : null);
    },
    cursor(cursor) {
      this.map.setOptions({
        'draggableCursor': cursor
      });
    }
  },
  computed: {
    ...Vuex.mapState([
      'shapes',
      'address',
      'mode',
      'toolType',
      'nextLatLngPan',
      'mapCenter',
      'mapZoom',
      'currentSlotName',
      'currentControlOverlay'
    ]),
    ...Vuex.mapGetters([
      'currentShape',
      'hoveredShape'
    ])
  },
  methods: {
    handleMapDrag() {
      this.$emit('map-drag');
    },
    handleMapDragEnd() {
      this.$emit('map-drag-end');
    },    
    handleMapIdle() {
      
    },
    async waitForMap() {
      while(this.map === null) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
    },
    async waitForShapes() {
      while(this.initedShapes.length === 0) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
    },
    setShapeSelectOverlayVisibility(event, visible = true, shapePayload) {
      if (!this.shapeSelectOverlay) {
        this.shapeSelectOverlay = new ControlOverlay(this.map, {      
          options: {
            distance: 30,
            prefix: 'shapeselect',
            iconsOnly: false
          },
          buttons: [{
            icon: 'vector-polygon-icon',
            text: 'Select area',            
            title: 'Select area measurement',
            name: 'select-area',
            behavior: new ClickableBehavior(),
            tooltipPlacement: 'top'
          },{
            icon: 'arrow-expand-icon',
            text: 'Select distance',
            title: 'Select distance measurement',
            name: 'select-distance',
            behavior: new ClickableBehavior(),
            tooltipPlacement: 'bottom'
          }]          
        });
      }

      this.shapeSelectOverlay.dispatcher.off('select-area-click');      
      this.shapeSelectOverlay.dispatcher.off('select-distance-click');      
      this.shapeSelectOverlay.dispatcher.on('select-area-click', () => {
        this.$store.dispatch('setLastMapClickEvent', { event, delayedClear: true });
        this.$store.dispatch('setCurrentShape', shapePayload.area);
      });
      this.shapeSelectOverlay.dispatcher.on('select-distance-click', () => {
        this.$store.dispatch('setLastMapClickEvent', { event, delayedClear: true });
        this.$store.dispatch('setCurrentShape', shapePayload.distance);
      });

      if(visible && event.latLng) {
        this.shapeSelectOverlay.setPosition(event.latLng);
        this.$store.dispatch('dialogs/snackbar', { message: 'Select area or distance measurement'});
      }

      this.shapeSelectOverlay.setVisible(visible); 
    },
    setCursor() {
      let cursor;
      switch (this.mode) {
        case 'move':
          cursor = cursorNormal;
          break;
        
        case 'draw':
        case 'free':
          cursor = cursorDraw;
      }

      this.cursor = cursor;
    },
    getShapeById(id) {
      return this.initedShapes.find((shape) => shape.indexID == id);
    },
    getBounds() {
      if (this.map && this.initedShapes.length) {        
        const bounds = this.initedShapes.reduce((lastBounds, shape) => {                         
          return lastBounds.union(shape.getBounds());
        }, this.initedShapes[0].getBounds());

        return bounds;
      }

      return null;
    },
    getNewPaths({ type='area'}={}) {
      let interpolate = google.maps.geometry.spherical.interpolate;
      let bounds = this.map.getBounds();

      let NECorner = bounds.getNorthEast();
      let SWCorner = bounds.getSouthWest();

      let NWCorner = new google.maps.LatLng(NECorner.lat(), SWCorner.lng());
      let SECorner = new google.maps.LatLng(SWCorner.lat(), NECorner.lng());

      let NWXOffset = interpolate(NWCorner, NECorner, .4);
      let NWYOffset = interpolate(NWCorner, SWCorner, .4);

      let NEXOffset = interpolate(NECorner, NWCorner, .4);
      let NEYOffset = interpolate(NECorner, SECorner, .4);

      let SEXOffset = interpolate(SECorner, SWCorner, .4);
      let SEYOffset = interpolate(SECorner, NWCorner, .4);

      let SWXOffset = interpolate(SWCorner, SECorner, .4);
      let SWYOffset = interpolate(SWCorner, NWCorner, .4);

      switch (type) {
        case 'area':      
          return [
            new google.maps.LatLng(NWYOffset.lat(), NWXOffset.lng()),
            new google.maps.LatLng(NEYOffset.lat(), NEXOffset.lng()),
            new google.maps.LatLng(SEYOffset.lat(), SEXOffset.lng()),
            new google.maps.LatLng(SWYOffset.lat(), SWXOffset.lng())
          ];

        case 'distance':
          return []
      }
    },
    updateMapCenter() {
      // if (this.map) {
      //   let center;
      //   const bounds = this.getBounds();

      //   if (bounds) {
      //     center = bounds.getCenter();        
      //   } else {
      //     center = this.map.getCenter();
      //   }     

      //   this.$store.dispatch('setMapCenter', Object.assign(center.toJSON(), { fromMap: true }));
      // }
    },

    initShape({ loadState = {}, options = {}}) {   
      defaults(options, {
         initialZoom: loadState.initialZoom || this.mapZoom.number,
         zoom: this.mapZoom.number
      });

      // console.log('in initshape, initial zoom: ', options.initialZoom);              
      const type = loadState.type || options.type || 'area';
      const cls = (type == 'area') ? 'Area' : ((type == 'distance') ? 'Distance' : null);
      
      if (cls) {
        if (!options.paths && !loadState.paths) {
          options.paths = this.getNewPaths({ type: 'area' });
        }
        
        let shape = new MapShapes[cls](this.map, cloneDeep(Object.assign(loadState, options)));        

        shape.dispatcher.on('updated', this.$store.dispatch.bind(this, 'refreshShape'));      

        shape.dispatcher.on('handleselected', () => this.setCursor());
        shape.dispatcher.on('handledeselected', () => {
          this.setCursor();
        });

        shape.dispatcher.on('modechanged', () => {        
          this.setCursor();
          this.initedShapes.filter(s => s.indexID !== shape.indexID).forEach(s => s.updateHandles());
        });
        
        this.initedShapes.push(shape);

        
        return shape;
      }
    },

    handleMapClick(event)
    {      
      if (!this.map.get('_noclicks')) {
        if (this.mode == 'free') {
          // store the event coordinates with a delayed clear so that future shape additional show the overlay centered over the shape
          this.$store.dispatch('setLastMapClickEvent', { event, delayedClear: true });

          this.$store.dispatch('addShape', new NEW_AREA({ 
            type: this.toolType,
            shape: 'free',
            paths: [this.$store.getters.lastMapClickLatLng]            
          }))
          .then(() => this.$store.dispatch('setMode', 'draw'));

          return;          
        }

        if (this.mode == 'draw') {
          if (this.currentInitedShape) {
            this.currentInitedShape.addCoordinate(event.latLng);
          }
        } else if (this.mode == 'move') {          
          if (get(this.currentControlOverlay, 'current') && this.currentControlOverlay.prefix == 'shapeselect-') {        
            this.setShapeSelectOverlayVisibility(null, false);            
          }

          let clickedDistanceShape = null;

          let clickedShapes = [...this.initedShapes.values()].filter((shape) => {            
            if (shape.isLatLngClickMatch(event.latLng)) {
              if (shape.getType() == 'distance') {
                clickedDistanceShape = shape;
              }

              return true;
            }
          }).sort((a1, a2) => {
            return (Math.abs(a1.getCalculated()) > Math.abs(a2.getCalculated())) ? 1 : -1;
          });          
          
          if (clickedShapes.length > 1 && clickedDistanceShape) {
            // both a distance and area were selected, show the shape selection overlay
            this.$store.dispatch('setCurrentShape', null);                    
            // store the click coords without delayed clear so that the shape ultimately selected has its overlay directly underneath where the shape selection overlay appeared    

            return this.setShapeSelectOverlayVisibility(event, true, { 
              area: clickedShapes.find(shape => shape.getType() == 'area'),
              distance: clickedShapes.find(shape => shape.getType() == 'distance')
            });            
          } 
          
          // store click coords with delayed clear since an area was directly selected and we want the control overlay to appear where the click occurred
          this.$store.dispatch('setLastMapClickEvent', { event, delayedClear: true });       
          // looks like an area was clicked. Since we're in move mode, go ahead and select it.   
          this.$store.dispatch('setCurrentShape', clickedShapes[0]);
        }
      }
    },

    async geocodeAddress(address)
    {
      let geocode = this.$store.dispatch('backend/geocodeAddress', address).then(({ latLng, accuracyType, formattedAddress }) => {
        if (accuracyType !== 'rooftop') {
          this.$store.dispatch('dialogs/confirm', { 
            priority: 0, 
            message: `Your search returned an estimated result and may not be accurate. Upgrade to a paid subscription for immediate access to premium searches.`,
            config: {
              title: 'Notice',
              confirmText: 'Upgrade',
              cancelText: 'Use less accurate result',              
              onConfirm: () => {                
                if (this.$store.getters['backend/user/current']) {
                  this.$router.replace({ name: 'Subscribe' });
                } else {                  
                  this.$router.replace({ name: 'Signin' });
                }
              }
            }
          }); 
        }

        this.$store.dispatch('setDisplayAddress', formattedAddress);
        return new google.maps.LatLng(latLng);
      }).catch(e => {
        if (e.message.includes('free-search-limit')) {
          this.$store.dispatch('dialogs/confirm', { 
            priority: 0, 
            message: `You've reached your free search limit this month. Upgrade to a paid subscription to continue searching for addresses immediately.`,
            config: {
              title: 'Notice',
              confirmText: 'Upgrade',
              cancelText: 'Wait until next month',
              onConfirm: () => {                
                if (this.$store.getters['backend/user/current']) {
                  this.$router.replace({ name: 'Subscribe' });
                } else {                  
                  this.$router.replace({ name: 'Signin' });
                }
              }
            }
          }); 
        } else if(e.message.includes('paid-search-limit')) {
          this.$store.dispatch('dialogs/confirm', { 
            priority: 0, 
            message: `You have reached your subscription's search limit. Upgrade for immediate access to additional searches.`,
            config: {
              title: 'Notice',
              confirmText: 'Upgrade',
              cancelText: 'Wait until next month',
              onConfirm: () => {                
                if (this.$store.getters['backend/user/current']) {                  
                  this.$router.replace({ name: 'Subscribe' });
                } else {                  
                  this.$router.replace({ name: 'Signin' });
                }
              }
            }
          });
        } else {
          this.$store.dispatch('dialogs/alert', {
            priority: 1,
            message: e.message,
            config: { type: 'is-danger' }
          });
        }
      });

      return geocode;
    },

    setAddressMarkerVisible(visible = true) {
      if (this.addressMarker) {
        this.addressMarker.setMap(visible ? this.map : null);
      }

      if (!visible) {
        return;
      }

      if (this.map && !this.addressMarker && this.mapCenter && this.mapCenter.lat && this.mapCenter.lng) {
        this.updateAddressMarker({ lat: this.mapCenter.lat, lng: this.mapCenter.lng });
      }
    },

    setAddressMarkerDraggable(draggable = true)
    {
      if (this.addressMarker) {
        this.addressMarker.setOptions({
          draggable
        });
      }
    },

    updateAddressMarker(latLng)
    {
      if (!this.addressMarker) {
        this.addressMarker = new google.maps.Marker({
          position: latLng,
          label: addressMarkerLabel,
          draggable: this.$route.name === 'ConfirmAddress'
        });

        this.addressMarker.addListener('dragend', (event) => {
          this.$store.dispatch('setMapCenter', event.latLng);
        });
      }

      this.addressMarker.setOptions({
        position: latLng
      });

      this.addressMarker.setMap(this.map);
      this.map.setCenter(latLng);
    },

    updateAddress(address)
    {      
      this.geocodeAddress(address).then((latLng) => {
        this.updateAddressMarker(latLng);

      }, function(result) {
        console.log(result);
      });
    }
  },

  mounted() {
    this.map = new google.maps.Map(this.$el, {
      center: this.latLng,
      zoom: 20,
      zoomControl: true,
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      disableDefaultUI: true,
      gestureHandling: 'greedy',
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM
      },
      tilt: 0
    });    

    let $this = this;

    this.map.addListener('click', this.handleMapClick);
    this.map.addListener('rightclick', this.handleMapRightClick);
    this.map.addListener('drag', this.handleMapDrag);
    this.map.addListener('dragend', this.handleMapDragEnd);
    
    this.map.addListener('center_changed', () => {
      this.$store.dispatch('setMapCenter', Object.assign(this.map.getCenter().toJSON(), { fromMap: true }));
    });

    const zoomFn = (zoom) => {            
      this.$store.commit('SET_MAP_ZOOM', { number: zoom || this.map.getZoom() || 19, fromMap: true});
    }
    this.map.addListener('zoom_changed', zoomFn.bind(this));
    this.map.addListener('idle', this.handleMapIdle.bind(this));
    // zoomFn.call(this, 19);
    

    function Overlay() {
      this.setMap($this.map);
    }

    Overlay.prototype = new google.maps.OverlayView();
    this.map.overlay = new Overlay();

    this.setCursor();    
    this.setAddressMarkerVisible(this.$route.name === 'ConfirmAddress');
    this.setAddressMarkerDraggable(this.$route.name === 'ConfirmAddress');
  }
}
</script>