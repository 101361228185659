import firebase from 'firebase/app'
import 'firebase/firestore'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    config: {}
  },
  mutations: {
    SET_CONFIG(state, config) {
      state.config = config;
    }
  },
  actions: {
    async waitForVal({ state }, key) {
      while (!state.hasOwnProperty('config') || !state.config.hasOwnProperty(key)) {        
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      console.log('key available', state.config[key]);
      return state.config[key];
    },
    async fetchConfig({ commit }) {
      try {
        const db = firebase.firestore();
        const configRef = db.collection('configuration').doc('client');
        const doc = await configRef.get();
        if (doc.exists) {
          commit('SET_CONFIG', doc.data());
        } else {
          console.log('Could not load configuration.');
        }
      } catch (error) {
        console.error('Error fetching configuration:', error);
      }
    }
  },
  getters: {
    config(state) {
      return state.config ? state.config : {};
    },
    verbiage(state) {
      return state.config.verbiage ? state.config.verbiage : {};
    }
  }
}
