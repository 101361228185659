<template>
  <div id="app" class="map-root-ct" @click="appClick"> 
    <tutorial-modal />
    <measure-map 
      ref="map"
      :enable-editing="meta.viewMode == 'edit'"
      @map-drag="handleMapDrag"
      @map-drag-end="handleMapDragEnd"
    ></measure-map>
    <status-panel
      v-if="meta.showStatusPanel"
      :show-measurement="meta.showMeasurements"
      :view-mode="meta.viewMode"
      v-on:add-shape-click="addMapShape">
    </status-panel>
    <control-panel
      v-if="meta.showControlPanel"
      :show-search-button="meta.showControlPanelSearchButton"
      v-on:add-shape-click="addMapShape">
    </control-panel>    
  </div>
</template>

<script>
import Vue from 'vue'

import VueObserveVisibility from 'vue-observe-visibility'

import 'buefy/dist/buefy.css'

import './icons'
import VueMq from 'vue-mq'

import StatusPanel from './components/StatusPanel.vue'
import MeasureMap from './components/MeasureMap.vue'
import ControlPanel from './components/ControlPanel.vue'
import TutorialModal from './components/TutorialModal.vue'

Vue.use(VueObserveVisibility);

Vue.use(VueMq, {
  breakpoints: {
    xs: 480,
    sm: 1024,
    md: 1200,
    lg: Infinity,
  }
});

export default {  
  components: {
    StatusPanel,
    MeasureMap,
    ControlPanel,
    TutorialModal
  },

  data() {
    return {
    }
  },
  mounted() {
    this.$emit('map-init');
  },

  methods: {
    appClick() {
      this.$store.dispatch('setAppClicked');
    },
    addMapShape(shapeCfg) {      
      if (shapeCfg.shape == 'free') {        
        this.$store.dispatch('setMode', 'free');
        this.$store.dispatch('setToolType', shapeCfg.type);
        
        return;
      }

      this.$store.dispatch('addShape', shapeCfg);
    },
    handleMapDrag() {
      this.$store.dispatch('setMapDrag', true);
    },
    handleMapDragEnd() {
      this.$store.dispatch('setMapDrag', false);
    }
  },

  computed: {
    meta() {
      return this.$route.meta;
    }
  }
}
</script>
