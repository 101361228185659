<template>
  
  <div class="modal-card-body has-text-centered">
    <p v-html="verbiage.address_search_paragraph"></p>
    <b-field position="is-centered">
      <b-input 
        ref="addressInput" 
        @keyup.enter.native="search" 
        v-model="inputAddress" 
        placeholder="Enter your address">
      </b-input>
      <p class="control">
        <b-button @click="search" id="search-button" type="is-primary">
          <magnify-icon />
          Search
        </b-button>
      </p>
    </b-field>
  </div>    
  
</template>

<script>
import LeadWorkflowNavigationButtons from '@/components/lead_workflow_components/LeadWorkflowNavigationButtons.vue';
import { snackbarDefaults } from '../../constants';

export default {
  name: 'AddressSearch',
  components: {
    LeadWorkflowNavigationButtons
  },
  data() {
    return {
      inputAddress: ''
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    isFirst: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    },
    nextButtonClicked: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    nextButtonClicked: function(val) {
      if (val) {
        this.search();
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async search() {
      if (this.inputAddress.length) {
        await this.$store.dispatch('searchAddress', this.inputAddress);
        this.$router.push(`/flow/confirm-address`);

      } else {
        this.$buefy.snackbar.open({
          ...snackbarDefaults,
          message: `Please enter an address.`
        });        
      }
    },
  },
  mounted() {
    this.$emit('set-title', this.verbiage.address_search_title);
    this.$nextTick(() => {
      this.$refs.addressInput.focus();
    });

    this.$emit('set-prev-route', '/flow/introduction');
    this.$emit('set-next-button-label', 'Search');
    this.$emit('set-show-next', true);
  }
}
</script>

<style scoped>
  .modal-card-body > p {
    padding-bottom: 1em;
  }
</style>