export default {
  namespaced: true,
  state: {
    projectName: null,
    firstName: null,
    lastName: null,
    email: null,
    productInterest: null,
    agree: false
  },
  mutations: {
    SET_PROJECT_NAME(state, { projectName }) {
      state.projectName = projectName;
    },
    SET_FIRST_NAME(state, { firstName }) {
      state.firstName = firstName;
    },
    SET_LAST_NAME(state, { lastName }) {
      state.lastName = lastName;
    },
    SET_EMAIL(state, { email }) {
      state.email = email;
    },
    SET_PRODUCT_INTEREST(state, { productInterest }) {
      state.productInterest = productInterest;
    },
    SET_AGREE(state, { agree }) {
      state.agree = agree;
    },
    RESET_STATE(state) {
      state.projectName = null;
      state.firstName = null;
      state.lastName = null;
      state.email = null;
      state.productInterest = null;
      state.agree = false;
    }
  },
  getters: {
    contactInfo(state) {
      return {
        projectName: state.projectName,
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        productInterest: state.productInterest,
        agree: state.agree
      };
    }
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    async saveContactInfo({ commit }, { data }) {
      commit('SET_PROJECT_NAME', { projectName: data.projectName });
      commit('SET_FIRST_NAME', { firstName: data.firstName });
      commit('SET_LAST_NAME', { lastName: data.lastName });
      commit('SET_EMAIL', { email: data.email });
      commit('SET_PRODUCT_INTEREST', { productInterest: data.productInterest });
      commit('SET_AGREE', { agree: data.agree });
    }
  }
}
