<template>
  <div :class="['toolbar-container', $mq]" v-bind:style="toolbarStyle">
    <div :class="['text-container', $mq]">
      <div class="logo-container">
        <img :src="`${clientResourcesUrl}logo.jpeg?alt=media`" alt="App Logo" class="app-logo">
      </div>      

      <div class="text">
        <h1 class="title is-4">{{ verbiage.confirm_address_title }}</h1>
        <p v-html="paragraph"></p>
      </div>
    </div>
    
    <div :class="['controls', $mq]">      
      <b-button expanded @click="reenterAddress" class="prev-button">
        <close-circle-icon />
        RE-ENTER ADDRESS
      </b-button>
    
    
      <b-button expanded type="is-primary" @click="confirmAddress">
        <check-icon />
        YES
      </b-button>       
    </div>
  </div>
</template>

<style scoped>
  .toolbar-container {    
    position: fixed;
    top: 0;    
    left: 0;
    width: 100%;    
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 0 .8em .8em 0;
    background-color: #fff;

    display: flex;
    flex-wrap: wrap;
  }

  div.text-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-grow: 1;
  }

  div.text {
    padding: .2em 0 0 .5em;
  }

  div.controls {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 1;
    padding-left: 10px;    
    justify-content: flex-end;
  }

  div.toolbar-container.xs div.controls {
    align-items: center;
  }

  div.controls button {
    margin: 2px 5px;
    max-width: 200px;
  }

  div.toolbar-container.xs div.controls button {
    max-width: none;
  }


  div.controls.lg, div.controls.md, div.controls.sm {    
    flex-direction: row;    
  }

  div.logo-container {
    
  }
   
  .app-logo {
    max-width: 80px;
  }  

  div.toolbar-container.xs div.logo-container {
    position: absolute;
    top: 0;
    right: 0;
  }

  div.toolbar-container.xs div.logo-container .app-logo {
    max-width: 50px;
  }

  h1.title {
    margin-bottom: 0;
  }

  div.toolbar-container.xs h1.title {
    font-size: 1.3em;
    padding: 10px 0 5px;
  }  

  div.toolbar-container.xs div.text p {
    padding-top: 10px;
  }

  /* make the buttons #467839 */
  div.controls button {
    
  }

  /* a nice hover color for button */
  div.controls button:hover {
  }
</style>

<script>
  window.Vue = require('vue').default;

  import Vuex from 'vuex'  
  
  export default {
    data: function() {
      return {
        
      }
    },
    computed: {
      hide() {
        return this.$store.state.mapDrag;
      },
      clientResourcesUrl() {
        return `https://firebasestorage.googleapis.com/v0/b/${process.env.VUE_APP_FIREBASE_STORAGE_BUCKET}/o/client%2F`;
      },
      toolbarStyle() {
        return {
          opacity: this.hide ? 0 : 1,
          visibility: this.hide ? 'hidden' : 'visible',
          transition: 'opacity 0.5s ease, visibility 0.5s ease'
        };
      },
      formattedAddress() {
        return this.$store.state.displayAddress || this.$store.state.address;
      },
      paragraph() {
        return this.verbiage.confirm_address_paragraph.replace('{{ address }}', this.formattedAddress);
      }
    },
    methods: {      
      confirmAddress() {
        this.$router.push('/flow/tutorial');
      },
      reenterAddress() {
        if (this.$store.getters.shapes.length > 0) {
          this.$store.dispatch('dialogs/confirm', { 
            priority: 0, 
            message: `Re-entering the address will remove all measurement layers from the map. <br><br><span class="has-text-weight-bold">Are you sure?</span>`,
            config: {
              title: 'Warning',
              confirmText: 'Yes, re-enter address',
              cancelText: 'Cancel',
              type: 'is-primary',
              onConfirm: async () => {                       
                await this.$store.dispatch('initNewMap');
                this.$router.push('/flow/address-search');        
              }
            }
          });
          return;
        }

        this.$router.push('/flow/address-search');        
      }
    },
    mounted() {
      this.$store.dispatch('dialogs/snackbar', { 
        message: 'Drag the marker to center it above your property.', 
        config: {
          duration: 5000 
        }
      });
    }
  }
</script>