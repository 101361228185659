<template>
  <div>
    <div class="modal-card-body">        
      <VideoCarousel 
        :items="carousels"
        :current-item="currentItem"
        :public-path="publicPath"
        @carousel-item-changed="handleCarouselItemChanged"
      />
    </div>
  </div>  
</template>

<style>
div.modal-card-body {
  overflow: auto;
}
</style>

<script>
import LeadWorkflowNavigationButtons from '@/components/lead_workflow_components/LeadWorkflowNavigationButtons.vue';
import VideoCarousel from '@/components/VideoCarousel.vue';

export default {
  name: 'Tutorial',
  props: {
    allVideosViewedAction: {
      type: String,
      default: 'route',
      validator: function (value) {
        return ['route', 'event'].indexOf(value) !== -1
      }
    },
    nextRoute: {
      type: String,
      default: '/flow/outline-areas'
    },
    prevRoute: {
      type: String,
      default: '/flow/confirm-address'
    },
    nextButtonLabel: {
      type: String,
      default: 'Next'
    },
    showPrev: {
      type: Boolean,
      default: true
    },
    showControls: {
      type: Boolean,
      default: true
    },
    nextButtonClicked: {
      type: Boolean,
      default: false
    },
  },
  components: {
    VideoCarousel,
    LeadWorkflowNavigationButtons    
  },
  data(){
    return {      
      publicPath: process.env.BASE_URL,
      currentItem: 0,
      carousels: [
        { text: 'Use the <span class="has-text-weight-bold">Area (free draw)</span> tool to outline areas that require paving work.', tipSrc: 'videos/tutorial-1.mp4' },                                
        { text: `If necessary, subtract areas from your measurements by flipping the <span class="has-text-weight-bold">Subtract</span> switch.`, tipSrc: 'videos/tutorial-2.mp4' },
        { text: `Label your measurements to keep your project organized.`, tipSrc: 'videos/tutorial-3.mp4' },
      ]
    }
  },
  watch: {
    nextButtonClicked: function(val) {
      if (val) {
        this.nextTutorialTip();
      }
    }
  },
  methods: {
    handleCarouselItemChanged(index) {
      this.currentItem = index;
    },
    nextTutorialTip() {
      if (this.currentItem < this.carousels.length - 1) {
        this.currentItem++;
        return;
      }

      if (this.allVideosViewedAction === 'event') {
        this.$emit('all-videos-viewed');
        return;
      }

      this.$router.push(this.nextRoute);
    }
  },
  mounted() {
    this.$emit('set-title', this.verbiage.outline_areas_tutorial_title);

    if (!this.showControls) {
      this.$emit('set-show-controls', false);
      return;
    }

    this.$emit('set-prev-route', this.prevRoute);
    this.$emit('set-next-button-label', this.nextButtonLabel);
    this.$emit('set-show-next', true);
    this.$emit('set-show-prev', this.showPrev);
    
  }
}
</script>