<template>
  <div class="modal-card-body">                
    <p v-html="verbiage.introduction_paragraph"></p>
  </div>    
</template>

<script>
import LeadWorkflowNavigationButtons from '@/components/lead_workflow_components/LeadWorkflowNavigationButtons.vue';

export default {
  emits: ['set-title', 'reset'],
  components: {
    LeadWorkflowNavigationButtons
  },
  data() {
    return {
      
    }
  },
  props: {
    isFirst: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$emit('set-title', this.verbiage.introduction_title);
    this.$emit('reset');

    this.$emit('set-next-button-label', 'Next Step');
    this.$emit('set-next-route', '/flow/address-search');
    this.$emit('set-prev-route', null);
    this.$emit('set-show-prev', false);
    this.$emit('set-show-next', true);
  }
}
</script>

<style scoped>
/* Add your styles here */
</style>