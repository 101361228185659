<template>  
  <div class="modal-card-body">  
    <p class="has-text-centered" v-html="verbiage.get_measurements_paragraph"></p>
    <div class="container mt-5">
      <b-field label="Project Description">
          <b-input v-model="projectName" placeholder="Optional" :disabled="isDataSaved"></b-input>
      </b-field>
      <b-field grouped>
        <b-field expanded label="First Name" :message="firstName ? '' : 'First name is required'">
          <b-input v-model="firstName" required :disabled="isDataSaved"></b-input>
        </b-field>
        <b-field expanded label="Last Name" :message="lastName ? '' : 'Last name is required'">
          <b-input v-model="lastName" required :disabled="isDataSaved"></b-input>
        </b-field>
      </b-field>        
      <b-field expanded label="Email" :message="validEmail ? '' : 'Email is not valid'">
        <b-input v-model="email" type="email" required :disabled="isDataSaved"></b-input>
      </b-field>
      
      <b-field label="Product you're interested in">
        <b-select expanded v-model="productInterest" :disabled="isDataSaved">
          <option v-for="product in config.products" :value="product.value" :key="product.value">{{ product.label }}</option>
        </b-select>
      </b-field>

      <b-field position="is-centered">
        <b-checkbox :class="['terms', $mq]" v-model="agree" :disabled="isDataSaved"><span v-html="verbiage.get_measurements_privacy_policy_paragraph"></span></b-checkbox>
      </b-field>
    </div>
  </div>   
</template>

<style>
  .terms.sm span.control-label {
    font-size: 0.8em;
  }

  div.modal-card-body {
    overflow: auto;
  }

</style>

<script>
import LeadWorkflowNavigationButtons from '@/components/lead_workflow_components/LeadWorkflowNavigationButtons.vue';

export default {
  name: 'ContactInfo',
  components: {
    LeadWorkflowNavigationButtons
  },
  data() {
    return {
      projectName: this.$store.state.projectName || '',
      firstName: this.$store.state.contact.firstName || '',
      lastName: this.$store.state.contact.lastName || '',
      email: this.$store.state.contact.email || '',
      productInterest: this.$store.state.contact.productInterest,
      agree: this.$store.state.contact.agree || false
    }
  },
  props: {
    nextButtonClicked: {
      type: Boolean,
      default: false
    },  
  },
  watch: {
    validFields: {
      handler: function(val) {        
        this.$emit('set-next-button-enabled', val);
      },
      immediate: true
    },
    nextButtonClicked: function(val) {
      if (val) {
        this.getMeasurements();
      }
    }
  },
  methods: {
    async getMeasurements() {
      if (this.isDataSaved && this.$store.state.mapSharing.mapId) {
        this.$router.push('/flow/results');
        return;
      }

      this.$store.dispatch('contact/saveContactInfo', { data: this.$data });
      await this.shareMap();
      
      this.$router.push('/flow/results');
    },
    async shareMap() {
      try {
        this.$store.commit('SET_LOADING', { loading: true }, { root: true });
        this.$store.dispatch('dialogs/snackbar', { message: 'Processing your measurements...' });
        
        this.$store.dispatch('setProjectName', { projectName: this.projectName });        
        await this.$store.dispatch('mapSharing/shareMap');
      } catch (error) {
        this.$store.dispatch('dialogs/alert', {
          priority: 0,            
          message: `Could not process your measurements. ${error.message}`,
          config: { title: 'Error', type: 'is-error' }
        });

        throw error;
      } finally {        
        this.$store.commit('SET_LOADING', { loading: false }, { root: true });
      }
    },
  },
  computed: {    
    validFields() {
      return this.isFirstNameEntered && this.isLastNameEntered && this.isProductInterestSelected && this.isAgreeChecked && this.validEmail;
    },
    isFirstNameEntered() {
      return this.firstName !== '';
    },
    isLastNameEntered() {
      return this.lastName !== '';
    },
    isAgreeChecked() {
      return this.agree;
    },
    isProductInterestSelected() {
      return this.productInterest !== '';
    },
    validEmail() {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email).toLowerCase());
    },
    isDataSaved() {
      return (this.$store.state.contact.firstName && this.$store.state.contact.lastName && this.$store.state.contact.email) ? true : false;
    }
  },
  mounted() {
    this.$emit('set-title', this.verbiage.get_measurements_title);
    this.projectName = this.$store.state.projectName || '';
    this.firstName = this.$store.state.contact.firstName || '';
    this.lastName = this.$store.state.contact.lastName || '';
    this.email = this.$store.state.contact.email || '';
    this.productInterest = this.$store.state.contact.productInterest || '';
    this.agree = this.$store.state.contact.agree || false;

    this.$emit('set-prev-route', '/flow/outline-areas');
    this.$emit('set-next-button-label', 'GET MEASUREMENTS');
    this.$emit('set-show-next', true);    
  }
}
</script>

